@use "../abstracts/" as *;

.works {
  &__content {
    margin-top: 3rem;
    gap: 7rem;

    @include breakpoint(laptop){
      row-gap: 9rem;
    }
  }
}

.project {
  gap: 2rem;
  

  @include breakpoint(laptop) {
    grid-template-columns: 3fr 2fr;
    column-gap: 4rem;
  }

  &__title {
    font-size: var(--fs-fluid-m);
    letter-spacing: -1px;
    font-weight: var(--fw-regular);
  }

  &__skills {
    margin-top: 0.5rem;
    font-size: 1rem;
    display: flex;
    gap: 0.5rem;

    &-item {
      background-color: var(--color-accent-1);
      padding: 0.1rem 0.4rem;
      color: white;
      border-radius: 4px;
      font-size: 13px;
    }
  }

  &__description {
    margin-top: 1rem;
    @include breakpoint(laptop) {
      margin-top: 2rem;
    }
  }
  &__img-container {
    position: relative;
    border-radius: 1rem;
    cursor: pointer;
    overflow: hidden;
    box-shadow: var(--shadow);
    max-width: 400px;

    &:hover .project__overlay {
      top: 0;
      transition: all 0.5s;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__overlay {
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 3rem;
    background-color: var(--color-overlay);
    transition: all 0.3s;
  }
}
