@use "../abstracts" as *;

.scroll-top {
  position: fixed;
  background-color: var(--color-accent-1);
  right: 1rem;
  bottom: -20%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  transition: bottom 0.6s ease;
 

  &.show-scroll {
    bottom: 2rem;
  }
}
