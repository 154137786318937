@use "../abstracts/" as *;

.section{
    padding-top: 4rem;
    padding-bottom: 6rem;

    @include breakpoint(tablet){
        padding-top: 5rem;
        padding-bottom: 8rem;
    }

    @include breakpoint(laptop){
        padding-top: 8rem;
        padding-bottom: 12rem;
    }
}