:root {
  --header-height: 4rem;
  --shadow: 10px 20px 40px rgba(0,0,0,.1);
  --ham-before: top 0.1s ease-in 0.25s,opacity 0.1s ease-in;
  --ham-before-active: top 0.1s ease-out,opacity 0.1s ease-out 0.12s;
  --ham-after: bottom 0.1s ease-in 0.25s,transform 0.22s cubic-bezier(0.55,0.055,0.675,0.19);
  --ham-after-active: bottom 0.1s ease-out,transform 0.22s cubic-bezier(0.215,0.61,0.355,1) 0.12s;
}

body {
  font-family: var(--ff-primary);
  font-size: var(--fs-fluid-text);
  color: var(--color-text-dark);
  font-weight: var(--fw-regular);
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--color-accent-3);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: var(--color-light);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 20px;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}

h1, h2 {
  line-height: 1;
}

p {
  line-height: 1.6;
}

a:visited {
  text-decoration: none;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list],
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

button {
  background-color: transparent;
  cursor: pointer;
  border: none;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
*::before,
*::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
:root {
  /* font-family */
  --ff-primary: "Source Sans Pro", sans-serif;
}

:root {
  /** Colors **/
  --color-primary: #1a2238;
  --color-accent-1: hsl(14, 100%, 62%);
  --color-accent-2: hsl(47, 84%, 72%);
  --color-accent-3: hsl(231, 77%, 78%);
  --color-light: #f9f9fb;
  --color-text-dark: hsl(223, 38%, 26%);
  --color-dark: hsl(30, 9%, 9%);
  --color-overlay:hsla(224, 37%, 16%, 0.952) ;
}

.text-purple {
  color: var(--color-accent-3);
}

.text-red {
  color: var(--color-accent-1);
}

:root {
  /* font-sizes */
  --fs-fluid-xl: clamp(1.75rem, calc(0.9rem + 3vw), 4.5rem);
  --fs-fluid-l: clamp(2.25rem, calc(1.95rem + 1.5vw), 3.75rem);
  --fs-fluid-m: clamp(2.19rem, calc(1.94rem + 1.25vw), 3.25rem);
  --fs-fluid-text: clamp(1.13rem, calc(1.05rem + 0.38vw), 1.5rem);
  /* font-weight */
  --fw-light: 200;
  --fw-regular: 400;
  --fw-semibold: 600;
  --fw-bold: 700;
  --fw-xbold: 900;
}

.section-title {
  font-size: var(--fs-fluid-l);
  color: var(--color-accent-1);
  font-weight: var(--fw-bold);
  margin-bottom: 1.25rem;
  letter-spacing: -1px;
}
.section-title--light {
  color: var(--color-light);
}

.btn {
  font-size: var(--fs-fluid-xl);
  color: var(--color-accent-1);
  font-weight: 900;
  text-decoration: underline wavy;
  text-decoration-color: var(--color-accent-2);
  text-decoration-thickness: 3px;
  transition: opacity 0.3s;
}
@media (min-width: 48rem) {
  .btn {
    text-decoration-thickness: 4px;
  }
}
@media (min-width: 68.75rem) {
  .btn {
    text-decoration-thickness: 6px;
  }
}
.btn:hover {
  opacity: 0.75;
}
.btn-small {
  color: var(--color-light);
  font-size: var(--fs-fluid-text);
  text-decoration-thickness: 3px;
  text-decoration-color: var(--color-accent-3);
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  width: 45px;
  height: 45px;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.3s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: var(--color-light);
  border-radius: 50%;
  border: 0;
  margin: 0;
  overflow: visible;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hamburger.show-menu {
  background-color: var(--color-accent-1);
}

.hamburger.show-menu .hamburger-inner,
.hamburger.show-menu .hamburger-inner::before,
.hamburger.show-menu .hamburger-inner::after {
  background-color: var(--color-light);
}

.hamburger-box {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 30px;
  height: 4px;
  background-color: var(--color-primary);
  border-radius: 4px;
  position: absolute;
  transition-property: transform, background-color;
  transition-duration: 0.7s;
  transition-timing-function: ease;
  pointer-events: none;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -9px;
}

.hamburger-inner::after {
  bottom: -9px;
}

.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r.show-menu .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin-r.show-menu .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin-r.show-menu .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  padding-inline: 1.5rem;
  padding-block: 1rem;
  z-index: 999;
  background-color: var(--color-primary);
  transition: all 0.5s;
}
@media (min-width: 68.75rem) {
  .header {
    padding-top: 2rem;
    padding-inline: 5rem;
  }
}
.header.scroll-down {
  transform: translate3d(0, -100%, 0);
}
.header.scroll-up {
  box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.1);
  background-color: rgba(26, 34, 56, 0.89);
  padding-block: 0;
}

.main-nav {
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-nav__logo {
  color: var(--color-light);
  font-size: 1rem;
  line-height: 0.8;
  font-weight: 700;
  transition: color 0.5s;
  text-decoration: none;
}
.main-nav__logo.active {
  color: var(--color-primary);
  z-index: 999;
}
.main-nav__menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-light);
  transition: 0.4s ease-out;
}
.main-nav__menu.active {
  right: 0;
}
@media (min-width: 68.75rem) {
  .main-nav__menu {
    position: initial;
    background-color: initial;
  }
}
.main-nav__list {
  width: 100%;
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.25rem;
}
@media (min-width: 68.75rem) {
  .main-nav__list {
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
  }
}
.main-nav__link {
  font-size: 1.75rem;
  color: var(--color-text-dark);
  font-weight: var(--fw-semibold);
  letter-spacing: 1px;
  text-decoration: none;
}
@media (min-width: 68.75rem) {
  .main-nav__link {
    color: var(--color-light);
    font-size: 1.25rem;
    font-weight: var(--fw-bold);
  }
}

.hero {
  position: relative;
  height: 100vh;
  color: var(--color-light);
  background-color: var(--color-primary);
  margin-bottom: 2rem;
}
.hero__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.hero__content h1 {
  font-size: var(--fs-fluid-xl);
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0px;
}
@media (min-width: 68.75rem) {
  .hero__content h1 {
    line-height: 1.2;
    margin-top: 3rem;
  }
}
.hero__content-social {
  width: 100%;
  display: flex;
  gap: 1rem;
  margin-top: 2.5rem;
}
@media (min-width: 68.75rem) {
  .hero__content-social {
    gap: 3rem;
    margin-top: 4rem;
  }
}
.hero__content-social svg {
  width: 3rem;
  height: 3rem;
}
@media (min-width: 68.75rem) {
  .hero__content-social svg {
    width: 4.5rem;
    height: 4.5rem;
  }
}
.hero__content-btn {
  margin-top: 2rem;
}

.about__content {
  gap: 3rem;
}
@media (min-width: 48rem) {
  .about__content {
    grid-template-columns: 3fr 2fr;
    column-gap: 3rem;
  }
}
.about__description {
  gap: 1rem;
}
.about__description h3 {
  margin-top: 2rem;
}
.about__img-container {
  max-width: 300px;
  justify-self: center;
}
.about__img {
  object-fit: cover;
}

.skills__icons {
  padding-top: 3.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 2rem;
  align-items: flex-start;
}
@media (min-width: 68.75rem) {
  .skills__icons {
    column-gap: 8rem;
    row-gap: 3.5rem;
    padding-top: 6rem;
  }
}
.skills__icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.skills__icon p {
  text-decoration: underline wavy;
  text-decoration-color: var(--color-accent-2);
}
.skills__icon svg {
  width: 4.25rem;
  height: 4.25rem;
}
@media (min-width: 68.75rem) {
  .skills__icon svg {
    width: 7rem;
    height: 7rem;
  }
}
.skills__icon svg path {
  fill: var(--color-primary);
}
.skills__icon p {
  text-align: center;
}

.works__content {
  margin-top: 3rem;
  gap: 7rem;
}
@media (min-width: 68.75rem) {
  .works__content {
    row-gap: 9rem;
  }
}

.project {
  gap: 2rem;
}
@media (min-width: 68.75rem) {
  .project {
    grid-template-columns: 3fr 2fr;
    column-gap: 4rem;
  }
}
.project__title {
  font-size: var(--fs-fluid-m);
  letter-spacing: -1px;
  font-weight: var(--fw-regular);
}
.project__skills {
  margin-top: 0.5rem;
  font-size: 1rem;
  display: flex;
  gap: 0.5rem;
}
.project__skills-item {
  background-color: var(--color-accent-1);
  padding: 0.1rem 0.4rem;
  color: white;
  border-radius: 4px;
  font-size: 13px;
}
.project__description {
  margin-top: 1rem;
}
@media (min-width: 68.75rem) {
  .project__description {
    margin-top: 2rem;
  }
}
.project__img-container {
  position: relative;
  border-radius: 1rem;
  cursor: pointer;
  overflow: hidden;
  box-shadow: var(--shadow);
  max-width: 400px;
}
.project__img-container:hover .project__overlay {
  top: 0;
  transition: all 0.5s;
}
.project__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.project__overlay {
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  background-color: var(--color-overlay);
  transition: all 0.3s;
}

.contact__container {
  padding-top: 4rem;
  padding-bottom: 7rem;
}
.contact__title {
  font-size: var(--fs-fluid-xl);
  line-height: 1.2;
}

.footer {
  position: relative;
  background-color: var(--color-primary);
  padding-top: 3rem;
  padding-bottom: 1rem;
}
.footer__container {
  gap: 2rem;
}
.footer__section {
  display: flex;
  gap: 2rem;
}
.footer__section-item {
  padding-bottom: 0.5rem;
}
.footer__section-link {
  font-size: var(--fs-fluid-text);
  color: var(--color-light);
  font-weight: var(--fw-semibold);
  text-decoration: none;
  margin-bottom: 1rem;
}
.footer__social {
  display: flex;
  gap: 1rem;
}
.footer small {
  color: lightslategrey;
}

.container {
  width: calc(100% - 3rem);
  max-width: 1200px;
  margin: 0 auto;
}
@media (min-width: 48rem) {
  .container {
    width: calc(100% - 6rem);
  }
}

.section {
  padding-top: 4rem;
  padding-bottom: 6rem;
}
@media (min-width: 48rem) {
  .section {
    padding-top: 5rem;
    padding-bottom: 8rem;
  }
}
@media (min-width: 68.75rem) {
  .section {
    padding-top: 8rem;
    padding-bottom: 12rem;
  }
}

.grid {
  display: grid;
}

.flex {
  display: flex;
}

.scroll-top {
  position: fixed;
  background-color: var(--color-accent-1);
  right: 1rem;
  bottom: -20%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  transition: bottom 0.6s ease;
}
.scroll-top.show-scroll {
  bottom: 2rem;
}

@media (min-width: 68.75rem) {
  .laptop-screen-hide {
    display: none;
  }
}

@media (min-width: 30rem) {
  .mobile-screen-hide {
    display: none;
  }
}

.overflow-hidden {
  overflow: hidden;
  margin: 0;
  touch-action: none;
  -ms-touch-action: none;
  /* position: fixed; we get rid of this line which resets the flow of the page */
  /* height: 100%; we change 100% to 100vh */
  height: 100vh;
}

.underline-yellow {
  text-decoration: underline wavy;
  text-decoration-thickness: 2px;
  text-decoration-color: var(--color-accent-2);
}