@use "../abstracts/" as *;

.skills {
  &__icons {
    padding-top: 3.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 2rem;
    align-items: flex-start;

    @include breakpoint(laptop){
      column-gap: 8rem;
      row-gap: 3.5rem;
      padding-top: 6rem;
      
    }
  }

  &__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    p{
      text-decoration: underline wavy;
      text-decoration-color: var(--color-accent-2);
    }

    & svg {
      width: 4.25rem;
      height: 4.25rem;

      @include breakpoint(laptop){
        width: 7rem;
        height: 7rem;
      }
    }

    & svg path {
      fill: var(--color-primary);
    }
    & p {
      text-align: center;
    }
  }
}
