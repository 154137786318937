@use "../abstracts/" as *;

.header {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  padding-inline: 1.5rem;
  padding-block: 1rem;
  z-index: 999;
  background-color: var(--color-primary);

  transition: all 0.5s;

  @include breakpoint(laptop) {
    padding-top: 2rem;
    padding-inline: 5rem;
  }

  &.scroll-down {
    transform: translate3d(0, -100%, 0);
  }

  &.scroll-up {
    box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.1);
    background-color: hsla(224, 37%, 16%, 0.89);
    padding-block: 0;
  }
}

.main-nav {
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  // transform: translateX(100%);

  &__logo {
    color: var(--color-light);
    font-size: 1rem;
    line-height: 0.8;
    font-weight: 700;
    transition: color 0.5s;
    text-decoration: none;

    &.active {
      color: var(--color-primary);
      z-index: 999;
    }
  }

  &__menu {
    position: fixed;
    top: 0;

    bottom: 0;
    right: -100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-light);
    transition: 0.4s ease-out;

    &.active {
      right: 0;
    }

    @include breakpoint(laptop){
      position: initial;
      background-color: initial;
    }
  }

  &__list {
    width: 100%;
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.25rem;

    //text-align: center;

    @include breakpoint(laptop){
      flex-direction: row;
      justify-content: center;
      gap: 2rem;
    }
  }

  &__link {
    font-size: 1.75rem;
    color: var(--color-text-dark);
    font-weight: var(--fw-semibold);
    letter-spacing: 1px;
    text-decoration: none;

    @include breakpoint(laptop){
      color: var(--color-light);
      font-size: 1.25rem;
      font-weight: var(--fw-bold);
    }
  }
}
