:root {
  /* font-sizes */
  --fs-fluid-xl: clamp(1.75rem, calc(0.9rem + 3vw), 4.5rem);
  --fs-fluid-l: clamp(2.25rem, calc(1.95rem + 1.5vw), 3.75rem);
  --fs-fluid-m: clamp(2.19rem, calc(1.94rem + 1.25vw), 3.25rem);
  --fs-fluid-text: clamp(1.13rem, calc(1.05rem + 0.38vw), 1.5rem);

  /* font-weight */
  --fw-light: 200;
  --fw-regular: 400;
  --fw-semibold: 600;
  --fw-bold: 700;
  --fw-xbold: 900;
}

.section-title {
  font-size: var(--fs-fluid-l);
  color: var(--color-accent-1);
  font-weight: var(--fw-bold);
  margin-bottom: 1.25rem;
  letter-spacing: -1px;

  &--light {
    color: var(--color-light);
  }
}
