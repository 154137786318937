@use "../abstracts/" as *;

.laptop-screen-hide{
    @include breakpoint(laptop){
        display: none;
    }
}

.mobile-screen-hide{
    @include breakpoint(phone){
        display: none;
    }
}

