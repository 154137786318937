@use "../abstracts/" as *;

.btn{
  font-size: var(--fs-fluid-xl);
  color: var(--color-accent-1);
  font-weight: 900;
  text-decoration: underline wavy;
  text-decoration-color: var(--color-accent-2);
  text-decoration-thickness: 3px;
  transition: opacity 0.3s;

  @include breakpoint(tablet) {
    text-decoration-thickness: 4px;
  }
  @include breakpoint(laptop) {
    text-decoration-thickness: 6px;
  }

  &:hover {
    opacity: 0.75;
  }

  &-small{
    color: var(--color-light);
    font-size: var(--fs-fluid-text);
    text-decoration-thickness: 3px;
    text-decoration-color: var(--color-accent-3);
  }
}
