:root {
  /** Colors **/
  --color-primary: #1a2238;
  --color-accent-1: hsl(14, 100%, 62%);
  --color-accent-2: hsl(47, 84%, 72%);
  --color-accent-3: hsl(231, 77%, 78%);
  --color-light: #f9f9fb;
  --color-text-dark: hsl(223, 38%, 26%);
  --color-dark: hsl(30, 9%, 9%);
  --color-overlay:hsla(224, 37%, 16%, 0.952) ;
}

.text-purple{
  color: var(--color-accent-3);
}

.text-red{
  color: var(--color-accent-1);
}
