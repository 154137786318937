@use "../abstracts/" as *;

.container {
  width: calc(100% - 3rem);
  max-width: 1200px;
  margin: 0 auto;

  @include breakpoint(tablet) {
    width: calc(100% - 6rem);
  }
}
