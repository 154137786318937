body {
 
  font-family: var(--ff-primary);
  font-size: var(--fs-fluid-text);
  color: var(--color-text-dark);
  font-weight: var(--fw-regular);
  // position: relative;
  // z-index: 1;
}


/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--color-accent-3);
}
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-track {
  background: var(--color-light);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 20px;
  
}
