.footer {
  position: relative;
  background-color: var(--color-primary);
  padding-top: 3rem;
  padding-bottom: 1rem;
  &__container {
    gap: 2rem;
  }

  &__section {
    display: flex;
    gap: 2rem;

    &-item {
      padding-bottom: .5rem;
    }

    &-link {
      font-size: var(--fs-fluid-text);
      color: var(--color-light);
      font-weight: var(--fw-semibold);
      text-decoration: none;
      margin-bottom: 1rem;
    }
  }

  &__social {
    display: flex;
    gap: 1rem;
  }

  small{
      color: lightslategrey;
  }
}
