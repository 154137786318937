/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  width: 45px;
  height: 45px;

  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.3s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: var(--color-light);
  border-radius: 50%;
  border: 0;
  margin: 0;
  overflow: visible;
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;
}
.hamburger.show-menu {
  background-color: var(--color-accent-1);
  
}

.hamburger.show-menu .hamburger-inner,
.hamburger.show-menu .hamburger-inner::before,
.hamburger.show-menu .hamburger-inner::after {
  background-color: var(--color-light);
}

.hamburger-box {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  pointer-events: none;

  display: flex;
  align-items: center;
  justify-content: center;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 30px;
  height: 4px;
  background-color: var(--color-primary);

  border-radius: 4px;
  position: absolute;
  transition-property: transform, background-color;
  transition-duration: 0.7s;

  transition-timing-function: ease;
  pointer-events: none;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -9px;
}
.hamburger-inner::after {
  bottom: -9px;
}

//Vortex

.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r.show-menu .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin-r.show-menu .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin-r.show-menu .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
