@use "../abstracts/" as *;

.hero {
  position: relative;
  height: 100vh;
  color: var(--color-light);
  background-color: var(--color-primary);
  margin-bottom: 2rem;

  

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h1 {
      font-size: var(--fs-fluid-xl);
      line-height: 1.5;
      font-weight: 700;
      letter-spacing: 0px;

      @include breakpoint(laptop) {
        line-height: 1.2;
        margin-top: 3rem;
      }
    }

    &-social {
      width: 100%;
      display: flex;
      gap: 1rem;
      margin-top: 2.5rem;

      @include breakpoint(laptop) {
        gap: 3rem;
        margin-top: 4rem;
      }

      svg {
        width: 3rem;
        height: 3rem;

        @include breakpoint(laptop) {
          width: 4.5rem;
          height: 4.5rem;
        }
      }
    }

    &-btn {
      margin-top: 2rem;
    }
  }

  
}
