@use "../abstracts/" as *;


.contact {
  &__container {
    padding-top: 4rem;
    padding-bottom: 7rem;

    
  }

  &__title {
    font-size: var(--fs-fluid-xl);
    line-height: 1.2;
  }
}
