@mixin breakpoint($size) {
    @media (min-width: map-get($breakpoints, $size)) {
      @content;
    }
  }
  
  $breakpoints: (
    "phone": "30rem",
    //480px
    "tablet": "48rem",
    //768px
    "laptop": "68.75rem",
    //1100px
    "desktop": "87.5rem",
    //1400px
  );
  
  // Example:
  // @include breakpoint(tablet){
  //     font-size: 3rem;
  // }