@use "../abstracts/" as *;

.about {
  &__content {
    gap: 3rem;

    @include breakpoint(tablet) {
      grid-template-columns: 3fr 2fr;
      column-gap: 3rem;
    }
  }

  &__description {
    gap: 1rem;

    & h3 {
      margin-top: 2rem;
    }
  }

  &__img-container {
    max-width: 300px;
    justify-self: center;
  }

  &__img {
    object-fit: cover;
  }
}
